<div class="form-container">
  <mat-card appearance="outlined" class="box">
    <mat-card-header class="justify-center mb-2">
      <h1 class="text-xl">Sign in to Inercomp Portal</h1>
    </mat-card-header>
    <mat-card-content class="w-full">
      <div class="w-full flex justify-center">
        <form
          [formGroup]="form"
          class="login-form gap-y-4"
          (ngSubmit)="loginViaCredentials()">
          <div>
            <formly-form
              [form]="form"
              [fields]="fields"
              [model]="model"></formly-form>
          </div>
          <div class="flex flex-col gap-2">
            <button
              mat-flat-button
              color="accent"
              type="submit"
              class="w-full"
              [disabled]="!form.valid">
              Continue
            </button>
            <div class="text-center">or</div>
            <button
              mat-stroked-button
              type="button"
              (click)="loginViaSSO()"
              class="w-full">
              Continue with SSO
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
