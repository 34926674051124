import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { logout } from './store/clear-state.metareducer';
import { AuthModule } from '@auth/auth.module';
import { MAT_PROGRESS_BAR_DEFAULT_OPTIONS } from '@angular/material/progress-bar';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    StoreModule.forRoot({}, { metaReducers: [logout] }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      // trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      // traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      serialize: {
        options: {
          map: true,
        },
      },
      connectInZone: true,
    }),
  ],
  providers: [
    provideCharts(withDefaultRegisterables()),
    {
      provide: MAT_PROGRESS_BAR_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
