import { Component, inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LoginCredentials } from '../../types/login-credentials';
import { Store } from '@ngrx/store';
import { LoginPageActions } from '../../store/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private store = inject(Store);
  private returnUrl = '/';
  protected model: LoginCredentials = {};
  protected fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'w-full flex justify-center flex-col gap-y-2',
      fieldGroup: [
        {
          key: 'username',
          type: 'input',
          wrappers: ['form-field', 'mat-suffix'],
          props: {
            required: true,
            color: 'accent',
            clear: true,
            label: 'Username',
          },
        },
        {
          key: 'password',
          type: 'input',
          wrappers: ['form-field', 'mat-suffix'],
          props: {
            required: true,
            type: 'password',
            color: 'accent',
            clear: true,
            label: 'Password',
          },
        },
      ],
    },
  ];
  protected form = new FormGroup({});

  /**
   * Return to the origin route if the user is logged in
   */
  ngOnInit(): void {
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
    this.store.dispatch(
      LoginPageActions.enterThePage({ returnUrl: this.returnUrl })
    );
  }

  /**
   * Submits the login form, logs the user in, and redirects to the return URL.
   *
   * @return {void}
   */
  loginViaCredentials(): void {
    this.store.dispatch(
      LoginPageActions.loginViaCredentials({
        credentials: { ...this.model },
        returnUrl: this.returnUrl,
      })
    );
  }

  /**
   * Logs the user in via SSO authentication.
   *
   * @return {void}
   */
  loginViaSSO(): void {
    this.store.dispatch(
      LoginPageActions.loginViaSSO({ returnUrl: this.returnUrl })
    );
  }
}
