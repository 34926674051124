import { ActionReducer } from '@ngrx/store';
import { LayoutSidenavActions } from '../auth/store/auth.actions';

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === LayoutSidenavActions.logout.type) {
      return reducer(undefined, action);
    }
    return reducer(state, action);
  };
}
