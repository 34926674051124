import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LoginRedirectPageActions } from '../../store/auth.actions';

@Component({
  selector: 'app-login-redirect',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss'],
})
export class LoginRedirectComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private store = inject(Store);

  /**
   * Initializes the component.
   *
   * This method is automatically called by Angular when the component is first created.
   * It extracts the returnUrl and code query parameters from the snapshot and dispatches
   * the appropriate actions to handle the authentication process.
   *
   * @returns {void}
   */
  ngOnInit() {
    const returnUrl =
      decodeURIComponent(this.route.snapshot.queryParams['state'.toString()]) ||
      '/';
    const code = this.route.snapshot.queryParams['code'.toString()];
    if (code !== undefined) {
      this.store.dispatch(
        LoginRedirectPageActions.getMicrosoftAuthInformation({
          returnUrl,
          code,
        })
      );
    } else {
      this.store.dispatch(LoginRedirectPageActions.logout());
    }
  }
}
